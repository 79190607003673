.submit-button {
    position: sticky;
    bottom: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-container {
    /* Add styles to your container */
    position: relative;
    background-color: red;
}
.upper-case {
    text-transform: uppercase;
}
  