html {
  /* scrollbar style start */
  // For Firefox
  scrollbar-color: tint-color($primary, 70%);

  // For Chrome
  ::-webkit {
    &-scrollbar {
      width: $spacer * 0.75;
      height: $spacer * 0.75;

      &-track-piece {
        background-color: tint-color($primary, 70%);
      }

      &-thumb:vertical {
        height: $spacer * 2;
        background-color: $primary;
      }
    }
  }

  /* scrollbar style end */

  @include media-breakpoint-down(lg) {
    font-size: $font-size-base * 0.875;
  }
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  background: $white url(../../../public/assets/images/body_bg.png) no-repeat top center / 100% auto;
  color: $dark;
  transition: $transition-base;
}

:focus {
  outline: none;
}

a {
  transition: $transition-base;

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 $spacer;

  @include media-breakpoint-down(lg) {
    margin: 0 0 $spacer * 0.75;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.course {
  margin: $spacer 0 $spacer;
}

// 404 page style
.cet_error_box {
  width: 500px;
  max-width: 100%;

  .cet_error_icon {
    width: 100%;
  }
}

// error page style
.cet_error_img {
  width: 300px;
  max-width: 100%;
}

// auth pages style
.cet_auth_box {
  max-width: 500px;
  width: 100%;
  box-shadow: 0px $spacer * 0.25 $spacer * 0.5 (-$spacer * 0.125) rgba($black, 0.05);
}

// modal close
.cet_modal_close_wrap {
  position: sticky;
  top: 0;

  .cet_modal_close {
    width: $spacer * 2.5;
    height: $spacer * 2.5;
    padding: $spacer * 0.6;
    border-radius: 0 0 0 50%;

    div {
      display: block;

      svg {
        display: block;
      }
    }
  }
}

.cet_auth_btn {
  padding: $spacer * 0.3;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: $spacer * 1.125;
    height: $spacer * 1.125;
  }
}

// datepicker
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    &.react-datepicker__view-calendar-icon {
      .form-control {
        padding: $input-btn-padding-y $input-btn-padding-x;
      }
      .react-datepicker__calendar-icon {
        right: $spacer;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: unset;
        padding: 0;
        width: $spacer * 1.25;
        height: $spacer * 1.25;
        &>div {
          svg {
            fill: $primary;
            display: block;
          }
        }
      }
    }
  }
}

.react-datepicker {
  .react-datepicker__navigation-icon {
    &:before {
      top: $spacer * 0.9;
      border-width: 2px 2px 0 0;
    }
  }

  .react-datepicker__current-month {
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;
  }

  .react-datepicker__triangle {
    transform: translateX(-50%) !important;
    left: 50% !important;
  }

  .react-datepicker__header__dropdown {
    &.react-datepicker__header__dropdown--select {
      display: flex;
      gap: $spacer * 0.5;
      padding: $spacer * 0.5 $spacer * 0.75;

      &>div {
        margin: 0;
        width: 100%;

        select {
          width: 100%;
          background-color: $white;
          border: 1px solid $input-border-color;
          border-radius: $input-border-radius;
          padding: $spacer * 0.25 $spacer * 1.5 $spacer * 0.25 $spacer * 0.5;
          font-size: $font-size-sm;
          color: $input-color;
          appearance: none;
          background: $white url('../../../public/assets/images/icons/arrow_down.svg') no-repeat right $spacer * 0.5 center;
          background-size: $spacer * 0.5 auto;
        }
      }
    }
  }
}


// react select
// .css-13cymwt-control,
// .css-t3ipsp-control,
// .css-16xfy0z-control {
//   border-radius: $input-border-radius !important;
//   border-color: $input-border-color !important;

//   .css-1fdsijx-ValueContainer {
//     padding: 0 $spacer;

//     .css-qbdosj-Input,.css-1h01tm3-Input {
//       padding: $spacer * 0.75 0 !important;
//       margin: 0 !important;
//     }
//   }
// }

// .css-t3ipsp-control {
//   border-color: $input-focus-border-color !important;
//   box-shadow: $input-focus-box-shadow !important;
// }

// .css-16xfy0z-control {
//   background-color: $input-disabled-bg !important;
// }