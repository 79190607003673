@import 'scss/variables';

.cet_social_list {
  display: flex;
  column-gap: $spacer * 0.75;
  li {
    a {
      svg {
        width: $spacer * 2;
        height: $spacer * 2;
        transition: $transition-base;
      }
      &:hover {
        svg {
          fill: currentColor;
        }
      }
    }
  }
}
