@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Martel:wght@200;300;400;600;700;800;900&display=swap');

@import 'scss/variables';
@import '~bootstrap/scss/bootstrap';
@import "~react-toastify/scss/main.scss";
@import "~react-datepicker/src/stylesheets/datepicker.scss";
@import 'scss/reboot';
@import 'scss/all';

// page and component styles
@import 'header.module.scss';
@import 'footer.module.scss';
@import 'iconcard.module.scss';
@import 'loader.module.scss';