@import 'scss/variables';

.cet_icon_card {
    background-color: $white;
    transition: $transition-base;
    &:hover {
        background-color: rgba($primary, 0.1);
        border-color: $primary !important;
    }
    .cet_icon_wrap {
        color: $primary;
    }
    svg {
        width: $spacer * 4;
        height: $spacer * 4;
    }
    a {
        svg {
            width: $spacer;
            height: $spacer;
            transition: $transition-base;
        }
        &:hover {
            svg {
                margin-left: $spacer * 0.5;
            }
        }
    }
    &.disabled {
        background-color: rgba($gray, 0.2) !important;
    }
}