@import 'scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.cet_logo {
  width: 80px;
  max-width: 100%;
  height: auto;

  &_pillar {
    width: $spacer * 3;
    margin: 0 auto;
  }

  &_wrap {

    @include media-breakpoint-down(lg) {
      position: relative;
    }
  }
}

.cet_text_logo {
  ._logo_hindi {
    font-family: $font-family-hindi;
  }
}

.header_cet_text_logo{
    border-left: 1px solid #7A7A7A;
    padding-left: 10px;
    width: 39rem;
}
.yellow_logo{
  width: 100px;
  max-width: 100%;
}

.right_logo{
  width: 46%;
}

.right_logo img{
  max-width: 5rem;
  @include media-breakpoint-down(md) {
    max-width: 4rem;
  }
}

.logo_heading{
  font-size: 22px;
  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
  
}
.slogan_title{
  font-size: 18px;
  @include media-breakpoint-down(md) {
    font-size: 10px;
  }
}