@import 'scss/variables';

.cet_about_image {
    padding-left: $spacer * 3;
    padding-top: $spacer * 2;
    max-width: 520px;
    img {
        position: relative !important;
        height: auto !important;
        border-radius: $border-radius-lg;
        width: 100%;
    }
    &::before {
        content: "";
        left: $spacer;
        top: $spacer * 1.5;
        position: absolute;
        width: calc(100% - $spacer * 2);
        height: calc(100% - $spacer * 3);
        border-radius: $border-radius-lg;
        background-color: $primary;
        transform: rotate(4.17deg);
    }
}